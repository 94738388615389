import { cn } from '#lib/utils.js'

export const Skeleton = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={cn('animate-pulse rounded-md bg-muted', className)}
      {...props}
    />
  )
}

export const TableSkeleton = ({
  columns = 5,
  rows = 25,
  className,
  ...props
}: {
  columns?: number
  rows?: number
} & React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={cn('grid gap-4', className)}
      style={{ gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))` }}
      {...props}
    >
      {Array.from({ length: rows * columns }).map((_, i) => (
        <Skeleton key={i} className="h-4 w-full" />
      ))}
    </div>
  )
}
